import { Row, Col, Card, Statistic, Skeleton, notification } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import FirebaseContext from "../firebase/context";
import { useContext, useEffect, useState } from "react";
import { DashboardMetrics } from "../api/models";

const DashboardPage = () => {
    const {state} = useContext(FirebaseContext);
    const api = state.api;

    const [metrics, setMetrics] = useState<DashboardMetrics>();

    useEffect(() => {
        loadMetrics();
    }, []);

    async function loadMetrics() {
        try {
            const resp = await api.getDashboardMetrics();
            setMetrics(resp);
        }
        catch(e) {
            notification.error({message: 'Failed to load at this time.'});
        }
    }

    return (
        <div>
            <h1>Dashboard</h1>
            {metrics ?
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Domains auto-acquire rate since last month"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Total Domain Hits"
                            value={metrics.totalHits}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix=""
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Total Keywords being tracked"
                            value={metrics.totalKeywords}
                            precision={0}
                            suffix=""
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Most Popular TLD"
                            value={metrics.mostPopularTLD}
                            precision={0}
                            suffix=""
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Most Popular ccTLD"
                            value="Not Available Yet"
                            precision={0}
                            suffix=""
                        />
                    </Card>
                </Col>
            </Row> : <Skeleton/>}
        </div>
    );
};

export default DashboardPage;
