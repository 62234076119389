import { Button, Col, Row, Space, Table } from "antd";
import { Key, useContext, useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { Keyword } from "../api/models";
import { format_date_short } from "../utils/date_utils";
import AddKeywordModal from "../components/add_keyword";
import { Link } from "react-router-dom";

const KeywordsPage = () => {
    const {state} = useContext(FirebaseContext);
    const [keywords, setKeywords] = useState<Keyword[]>();
    const api = state.api;

    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        loadKeywords();
    }, []);

    async function loadKeywords() {
        const keywords = await api.listKeywords();
        setKeywords(keywords.content);
    }

    function onFinishAdd(success: boolean) {
        if(success) {
            loadKeywords();
        }
        setShowAddModal(false);
    }

    const columns = [
        {
            title: 'Keyword',
            key: 'keyword',
            render: (k: Keyword) => {
                return <Link to={{pathname: "/hits", search: "keywords=" + encodeURIComponent(k.keyword)}}>{k.keyword}</Link>;
            }
        },
        {
            title: 'Date Added',
            key: 'createdAt',
            render: (k: Keyword) => {
                return format_date_short(k.createdAt);
            }
        },
        {
            title: 'Hits',
            key: 'currentHits',
            render: (data: any) => {
                return <span>{data.currentHits}</span>
            }
        },
    ];
    return (
        <div>
            <h1>Keyword Management</h1>
            <Button type="primary" onClick={() => setShowAddModal(true)}>Add New</Button>
            <Row>
                <Col span={24}>
                    <Table loading={!keywords} dataSource={keywords} rowKey="id" columns={columns} />
                </Col>
            </Row>
            {showAddModal && <AddKeywordModal onFinish={onFinishAdd}/>}
        </div>
    );
};

export default KeywordsPage;
