import { createContext, useEffect, useReducer } from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import { User, UserCredential, getAuth, onAuthStateChanged } from "firebase/auth";
import { APIClient } from "../api";

export interface IFirebaseState {
  authenticated?: boolean;
  api: APIClient;
  user?: User;
}

export interface IFirebaseContext {
  state: IFirebaseState;
  dispatch: (action: Action) => void;
}

const initialContext: IFirebaseContext = {
  state: {
    api: new APIClient('')
  },
  dispatch: (action: any) => {}
};

const FirebaseContext = createContext(initialContext);

export interface Props {
  children: any;
}

export interface ACTION_LOGIN {
  credential: UserCredential;
}

export interface ACTION_LOGOUT {}

export type Action = {type: 'loggedIn', user: User, idToken: string} |
  {type: 'logout'};

function reducer(state: IFirebaseState, action: Action): IFirebaseState {
  switch(action.type) {
    case 'loggedIn': 
      return {...state, authenticated: true, user: action.user, api: new APIClient(action.idToken)};
    case 'logout':
      getAuth(app).signOut();
      return {...state, authenticated: false, user: undefined};
    default:
      return state;
  }
}

export function FirebaseContextProvider(props: Props) {
  const [state, dispatch] = useReducer(reducer, {
    api: new APIClient('')
  });

  useEffect(() => {
    loadAuth();
  }, []);

  async function loadAuth() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if(user) {
        dispatch({type: 'loggedIn', user: user, idToken: (await user.getIdToken())});
      }
      else {
        dispatch({type: 'logout'});
      }
    });
  }

  return (
    <FirebaseContext.Provider value={{
      state, dispatch
    }}>
      {props.children}
    </FirebaseContext.Provider>
  );
}

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsdUImIap5kGP1j3bRPojBGGB5SpPaA-g",
  authDomain: "brandfort-dev.firebaseapp.com",
  projectId: "brandfort-dev",
  storageBucket: "brandfort-dev.appspot.com",
  messagingSenderId: "726490095561",
  appId: "1:726490095561:web:b3d7772c329f824193d218"
};

const app = initializeApp(firebaseConfig);


export default FirebaseContext;
