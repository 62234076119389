import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FirebaseContext from "../firebase/context";
import { Card, Descriptions, Spin } from "antd";
import { ScanHit } from "../api/models";
import { format_date_short } from "../utils/date_utils";
import EPPStatuses from "../components/epp_statuses";
import WhoisCard from "../components/whois_card";
import HitDetailsCard from "../components/hit_details_card";

const ViewHitPage = () => {
    const { state } = useContext(FirebaseContext);
    const api = state.api;
    const { id } = useParams();
    const [hit, setHit] = useState<ScanHit>();

    const hitId = id ? parseInt(id) : 0;

    useEffect(() => {
        loadHit();
    }, []);

    async function loadHit() {
        setHit(await api.getHit(hitId));
    }

    if (!hit) return <Spin />;

    return (
        <div>
            <h1>{hit.domain}</h1>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
                <HitDetailsCard hit={hit} />
                <WhoisCard whois={hit.whoisDomain} />
            </div>
        </div>
    );
};

export default ViewHitPage;
