import React, { useContext, useState } from 'react';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  LikeOutlined,
  LogoutOutlined,
  HomeOutlined,
  AimOutlined,
  AlertOutlined,
  UnorderedListOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import FirebaseContext from '../firebase/context';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  { label: <Link to="/">Dashboard</Link>, key: '/', icon: <HomeOutlined /> },
  { label: <Link to="/keywords">Keywords</Link>, key: '/keywords', icon: <UnorderedListOutlined /> },
  { label: <Link to="/hits">Domains</Link>, key: '/hits', icon: <AlertOutlined /> },
  { label: <Link to="/acquisitions">Acquisitions</Link>, key: '/acquisitions', icon: <AimOutlined /> },
  { label: <Link to="/social">Social</Link>, key: '/social', icon: <LikeOutlined /> },
  { label: <Link to="/settings">Account</Link>, key: '/settings', icon: <SettingOutlined /> },
  { label: 'Logout', key: '/logout', icon: <LogoutOutlined /> }
];

const Portal: React.FC = () => {
  const { state, dispatch } = useContext(FirebaseContext);
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  function onSelect(item: any) {
    if (item.key == '/logout') {
      dispatch({ type: 'logout' });
    }
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h1 style={{ color: 'white' }}>BrandFort</h1>
        </div>
        <Menu theme="dark" selectedKeys={[location.pathname]} mode="inline" items={items} onSelect={onSelect} />
      </Sider>
      <Layout>
        <Content style={{ margin: '16px 16px' }}>
          <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2023 BrandFort</Footer>
      </Layout>
    </Layout>
  );
};

export default Portal;
