import { Col, Row, Spin } from "antd";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import FirebaseContext from "../firebase/context";

const BaseLayout = () => {
    const {state} = useContext(FirebaseContext);

    if(state.authenticated == undefined) {
        return <Spin/>;
    }

    return (<>
        <Outlet />
    </>);
};

export default BaseLayout;
