import { Alert, Card, Col, Row } from "antd";

import { Button, Checkbox, Form, Input } from 'antd';
import { useContext, useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useForm } from "antd/es/form/Form";
import { Navigate } from "react-router-dom";

type FieldType = {
    email?: string;
    password?: string;
};

const LoginPage = () => {
    const {state, dispatch} = useContext(FirebaseContext);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [form] = useForm();
    console.log(state);

    const onFinish = async (values: any) => {
        form.resetFields();
        setErrorMessage(undefined);
        const auth = getAuth();
        try {
            const credential = await signInWithEmailAndPassword(auth, values.email, values.password)
            const idToken = await credential.user.getIdToken();
            dispatch({
                type: 'loggedIn',
                user: credential.user,
                idToken: idToken,
            });
        }
        catch (error: any) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error('error', error);
                console.log(error.message);
                setErrorMessage('Invalid email or password.');
            }
        };

        if(state.authenticated) {
            return <Navigate to="/"/>;
        }

        return <>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '700px', backgroundColor: '#cccc', padding: 15, borderRadius: '6px' }}>
                    <h3>BrandFort Login</h3>
                    {state.authenticated ? 'Logged in' : ''}
                    <Form
                        form={form}
                        name="basic"
                        initialValues={{ remember: true }}
                        labelCol={{span: 6}}
                        labelAlign="left"
                        onFinish={onFinish}
                    >
                        <Form.Item<FieldType>
                            label="Email Address"
                            name="email"
                            rules={[{ required: true, message: 'Email is required' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item<FieldType>
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Password is required' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        {errorMessage && <Alert style={{margin: 5}} type="error" message={errorMessage}/>}

                        <Form.Item wrapperCol={{ offset: 6 }}>
                            <Button type="primary" htmlType="submit">
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>;
    };

    export default LoginPage;
