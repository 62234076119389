import { Card } from "antd";
import { WhoisDomain } from "../api/models";
import { format_date_short } from "../utils/date_utils";
import EPPStatuses from "./epp_statuses";
import WhoisContactInfo from "./whois_contact_info";

export interface Props {
    whois?: WhoisDomain;
}

const WhoisCard = (props: Props) => {
    function get_nameservers() {
        if (props.whois?.nameservers) {
            return props.whois?.nameservers.split("|").join("\n");
        }
        else {
            return "Not Available";
        }
    }

    if (!props.whois) {
        return <Card title="WHOIS">Not Available</Card>;
    }
    else {
        return (
            <Card title="WHOIS">
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '10px', alignItems: 'start' }}>
                    <table style={{ borderSpacing: '5px' }}>
                        <tbody>
                            <tr>
                                <th>Registrar: </th>
                                <td>{props.whois.registrar}</td>
                            </tr>
                            <tr>
                                <th>Contact Email: </th>
                                <td>{props.whois.contactEmail}</td>
                            </tr>
                            <tr>
                                <th>Created Date: </th>
                                <td>{props.whois.createdAt ? format_date_short(props.whois.createdAt) : 'Not Available'}</td>
                            </tr>
                            <tr>
                                <th>Expires Date: </th>
                                <td>{props.whois.expiresAt ? format_date_short(props.whois.expiresAt) : 'Not Available'}</td>
                            </tr>
                            <tr>
                                <th>Nameservers: </th>
                                <td>{get_nameservers()}</td>
                            </tr>
                            <tr>
                                <th>Domain Statuses: </th>
                                <td><EPPStatuses statuses={props.whois.statuses} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <WhoisContactInfo title="Registrant Contact" contact={props.whois.registrantContact} />
                    <WhoisContactInfo title="Administrative Contact" contact={props.whois.adminContact} />
                    <WhoisContactInfo title="Technical Contact" contact={props.whois.technicalContact} />
                    <WhoisContactInfo title="Billing Contact" contact={props.whois.billingContact} />
                </div>
            </Card>
        );
    }
};

export default WhoisCard;
