import { useContext } from "react";
import FirebaseContext from "../firebase/context";
import { Navigate } from "react-router-dom";

export interface Props {
    children: any;
}

const RequiresAuth = (props: Props) => {
    const {state} = useContext(FirebaseContext);

    if(!state.authenticated) {
        return <Navigate to="/login"/>;
    }
    return <>{props.children}</>;
};

export default RequiresAuth;
