import { Button, Col, Dropdown, Input, Row, Space, Table, TablePaginationConfig } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import FirebaseContext from "../firebase/context";
import { Keyword, Page, ScanHit } from "../api/models";
import { PaginationConfig } from "antd/es/pagination";
import PaginatedTable, { LoadOptions, usePaginatedTable } from "../components/paginated_table";
import RegistrarSelector from "../components/registrar_selector";
import HitsFilters, { HitsFilterConfig } from "../components/hits_filters";
import { ListHitsOpts } from "../api";
import { Link, useSearchParams } from "react-router-dom";
import { format_date_short } from "../utils/date_utils";
import ScanHitTags from "../components/scan_hit_tags";
import { DownOutlined } from '@ant-design/icons';

export interface Props {
    inventoryOnly: boolean;
}

const ScanHitsPage = (props: Props) => {
    const { state } = useContext(FirebaseContext);
    const api = state.api;
    const paginatedTable = usePaginatedTable();
    const [searchParams, setSearchParams] = useSearchParams();
    const afterInitialLoad = useRef(false);

    const registrar = searchParams.get('registrars');
    const keywords = searchParams.get('keywords');
    const domain = searchParams.get('domain') || undefined;

    const [filters, setFilters] = useState<HitsFilterConfig>({
        registrars: registrar ? registrar.split('\n') : [],
        domainName: domain,
        keywords: keywords ? keywords.split('\n') : [],
    });

    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    /*useEffect(() => {
        const registrar = searchParams.get('registrars');
        const keywords = searchParams.get('keywords');
        const domain = searchParams.get('domain') || '';

        const newFilterConfig = {...filters};
        newFilterConfig.registrars = registrar ? registrar.split('\n') : [];
        newFilterConfig.domainName = domain;
        newFilterConfig.keywords = keywords ? keywords.split('\n') : [];
        setFilters(newFilterConfig);
    }, [searchParams]);*/

    useEffect(() => {
        if (afterInitialLoad.current) {
            paginatedTable.refresh(true);

            const newParams = new URLSearchParams(searchParams);
            if (filters.registrars.length > 0) newParams.set('registrars', filters.registrars.join('\n'));
            if (filters.domainName) {
                newParams.set('domain', filters.domainName)
            }
            else {
                newParams.delete('domain');
            }

            if (filters.keywords) newParams.set('keywords', filters.keywords.join('\n'));
            setSearchParams(newParams);
        }
        afterInitialLoad.current = true;
    }, [filters]);

    async function loadHits(opts: LoadOptions): Promise<Page<ScanHit>> {
        const filterConfig: ListHitsOpts = {};
        if (filters) {
            filterConfig.registrars = filters.registrars;
            filterConfig.keywords = filters.keywords;
            if (props.inventoryOnly) filterConfig.inventory = true;

            if (filters.domainName && filters.domainName.length > 0) {
                filterConfig.domain = filters.domainName;
            }
        }
        return await api.listHits(opts.page, opts.pageSize, filterConfig);
    }

    function onUpdateFilters(filters: HitsFilterConfig) {
        setFilters(filters);
    }

    const columns = props.inventoryOnly ?
        [
            {
                title: 'Keyword',
                key: 'keyword',
                render: (h: ScanHit) => {
                    return h.keyword.keyword;
                }
            },
            {
                title: 'Domain',
                key: 'domain',
                render: (h: ScanHit) => {
                    return <Link to={h.id.toString()}>{h.domain}</Link>
                }
            },
            {
                title: 'Price',
                key: 'price',
                render: (h: ScanHit) => {
                    if (!h.inventory) {
                        return 'N/A';
                    }
                    else {
                        return USDollar.format(h.inventory.priceCents / 100);
                    }
                }
            },
            {
                title: 'Source',
                key: 'source',
                render: (h: ScanHit) => {
                    if (!h.inventory) {
                        return 'N/A';
                    }
                    else {
                        return h.inventory.source;
                    }
                }
            },
            {
                title: 'Tags',
                key: 'tags',
                render: (h: ScanHit) => {
                    return <ScanHitTags scanHit={h} />;
                }
            },
            {
                title: 'Actions',
                key: 'actions',
                width: '20px',
                render: (h: ScanHit) => {
                    return (
                        <Dropdown menu={{
                            items: [
                                { label: 'Bid', key: 'bid' },
                            ]
                        }}>
                            <Button >
                                <Space>
                                    Actions
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    );
                }
            }
        ]
        : [
            {
                title: 'Keyword',
                key: 'keyword',
                render: (h: ScanHit) => {
                    return h.keyword.keyword;
                }
            },
            {
                title: 'Domain',
                key: 'domain',
                render: (h: ScanHit) => {
                    return <Link to={h.id.toString()}>{h.domain}</Link>
                }
            },
            {
                title: 'Tags',
                key: 'tags',
                render: (h: ScanHit) => {
                    return <ScanHitTags scanHit={h} />;
                }
            },
            {
                title: 'Registrar',
                key: 'registrar',
                render: (h: ScanHit) => {
                    return h.whoisDomain?.registrar || 'Unknown';
                }
            },
            {
                title: 'First Seen',
                key: 'firstSeenAt',
                render: (h: ScanHit) => {
                    return format_date_short(h.firstSeenAt);
                }
            },
        ];

    return (
        <div>
            <h1>{props.inventoryOnly ? 'Acquisitions' : 'Domains'}</h1>
            <Row>
                <Col span={24}>
                    <HitsFilters value={filters} onUpdate={onUpdateFilters} />
                    <PaginatedTable paginatedTable={paginatedTable} dataSourceLoader={loadHits} rowKey="id" columns={columns} />
                </Col>
            </Row>
        </div>
    );
};

export default ScanHitsPage;
