export interface Keyword {
    id: number;
    keyword: string;
    createdAt: Date;
    currentHits: number;
}

export interface ScanHit {
    id: number;
    firstSeenAt: Date;
    lastSeenAt: Date;
    keyword: Keyword;
    sld: string;
    tld: string;
    domain: string;
    whoisDomain: WhoisDomain;
    resolvable: boolean;
    spamhaus: boolean;
    parkingStatus: string;
    inventory?: Inventory;
}

export interface Inventory {
    priceCents: number;
    source: string;
}

export const ParkingStatusNone = "none";
export const ParkingStatusPaid = "paid";
export const ParkingStatusRegistrar = "registrar";

export interface WhoisDomain {
    domain: string;
    registrar: string;
    contactEmail: string;
    nameservers: string;
    createdAt: number;
    expiresAt: number;
    statuses: string;
    adminContact: WhoisContact;
    billingContact: WhoisContact;
    technicalContact: WhoisContact;
    registrantContact: WhoisContact;
}

export interface WhoisContact {
    email: string;
    name: string;
    organization: string;
    street1: string;
    street2: string;
    street3: string;
    street4: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    telephone: string;
    telephoneExt: string;
    fax: string;
    faxExt: string;
}

export interface Page<T> {
    content: T[];
    totalElements: number;
    current: number;
}

export interface DashboardMetrics {
    totalKeywords: number;
    totalHits: number;
    mostPopularTLD: string;
}

export interface CreateKeywordRequest {
    keyword: string;
}
