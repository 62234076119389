import { useContext, useState } from "react";
import FirebaseContext from "../firebase/context";
import { Alert, Form, Input, Modal, notification } from "antd";
import { useForm } from "antd/es/form/Form";

export interface Props {
    onFinish?: (success: boolean) => void;
}

const AddKeywordModal = (props: Props) => {
    const {state} = useContext(FirebaseContext);
    const api = state.api;
    const [form] = useForm();
    const [error, setError] = useState<string>();

    const [creating, setCreating] = useState(false);

    async function onFinish(values: any) {
        try {
            setError(undefined);
            await api.createKeyword({keyword: values.keyword});
            if(props.onFinish) props.onFinish(true);
        }
        catch(e) {
            setError('Failed to create at this time.');
        }
    }

    function onCancel() {
        if(props.onFinish) props.onFinish(false);
    }

    return (
        <Modal open={true} title="Add Keyword" okText="Create" confirmLoading={creating} onCancel={onCancel} onOk={() => form.submit()}>
            {error && <Alert style={{marginBottom: '10px'}} type="error" message={error}/>}
            <Form form={form} onFinish={onFinish}>
                <Form.Item name="keyword" label="Keyword" rules={[{required: true, message: 'Keyword is required'}]}>
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddKeywordModal;
