import { Button, Col, Row, Space, Table } from "antd";
import { useContext, useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { Keyword, ScanHit } from "../api/models";

const SocialHitsPage = () => {
    const {state} = useContext(FirebaseContext);
    const [hits, setHits] = useState<ScanHit[]>();
    const api = state.api;

    useEffect(() => {
        loadHits();
    }, []);

    async function loadHits() {
        const hits = await api.listHits(0, 0, {});
        setHits(hits.content);
    }
    const data = [
        {
            platform: 'X (Twitter)',
            handle: '@ElonMusk'
        }
    ];

    const columns = [
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Handle',
            dataIndex: 'handle',
            key: 'handle',
        },
    ];
    return (
        <div>
            <h1>Social</h1>
            <Row>
                <Col span={24}>
                    <Table loading={!hits} dataSource={data} rowKey="id" columns={columns} />
                </Col>
            </Row>
        </div>
    );
};

export default SocialHitsPage;
