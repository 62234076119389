import { Card } from "antd";
import { WhoisContact } from "../api/models";

export interface Props {
    contact?: WhoisContact;
    title: string;
}

const WhoisContactInfo = (props: Props) => {
    if (!props.contact || Object.keys(props.contact).length == 0) {
        return <Card title={props.title}>Not Available</Card>;
    }
    else {
        return (
            <Card title={props.title}>
                <table style={{ borderSpacing: '5px' }}>
                    <tbody>
                        <tr>
                            <th>Name: </th>
                            <td>{props.contact.name}</td>
                        </tr>
                        <tr>
                            <th>Organization: </th>
                            <td>{props.contact.organization}</td>
                        </tr>
                        <tr>
                            <th>Address: </th>
                            <td>
                                {[props.contact.street1, props.contact.street2, props.contact.street3, props.contact.street4].filter(c => !!c)
                                    .map(s => s).join("\n")}
                            </td>
                        </tr>
                        <tr>
                            <th>City: </th>
                            <td>{props.contact.city}</td>
                        </tr>
                        <tr>
                            <th>State/Province: </th>
                            <td>{props.contact.state}</td>
                        </tr>
                        <tr>
                            <th>Postal Code: </th>
                            <td>{props.contact.postalCode}</td>
                        </tr>
                        <tr>
                            <th>Country: </th>
                            <td>{props.contact.country}</td>
                        </tr>
                        <tr>
                            <th>Telephone: </th>
                            <td>{props.contact.telephone} {props.contact.telephoneExt}</td>
                        </tr>
                        <tr>
                            <th>Fax: </th>
                            <td>{props.contact.fax} {props.contact.faxExt}</td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        );
    }
};

export default WhoisContactInfo;
