import { Space, Tag } from "antd";

interface Props {
    statuses?: string;
}

const EPPStatuses = (props: Props) => {
    const tags = props.statuses ? props.statuses.split(" ") : [];
    return (
        <Space size={[0,8]}>
            {tags.map(tag => (
                <Tag key={tag} color="geekblue">{tag}</Tag>
            ))}
        </Space>
    );
};

export default EPPStatuses;
