import { Col, Input, Row } from "antd";
import RegistrarSelector from "./registrar_selector";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import KeywordSelector from "./keyword_selector";

export interface HitsFilterConfig {
    registrars: string[];
    domainName?: string;
    keywords: string[];
}

export interface Props {
    value: HitsFilterConfig;
    onUpdate?: (filters: HitsFilterConfig) => void;
}

const HitsFilters = (props: Props) => {
    function onRegistrarsUpdated(newRegistrars: string[]) {
        if(props.onUpdate) props.onUpdate({...props.value, registrars: newRegistrars});
    }

    function onDomainChange(e: any) {
        if(props.onUpdate) props.onUpdate({...props.value, domainName: e.target.value});
    }

    function onKeywordUpdated(newKeywords: string[]) {
        if(props.onUpdate) props.onUpdate({...props.value, keywords: newKeywords});
    }

    return (
        <Row style={{ marginBottom: '10px' }}>
            <Col style={{ display: 'flex', flexDirection: 'row', flexGrow: '1', gap: '10px' }}>
                <Input value={props.value.domainName} allowClear placeholder="Filter by Domain" onChange={onDomainChange} />
                <RegistrarSelector values={props.value.registrars} onChange={onRegistrarsUpdated} />
                <KeywordSelector values={props.value.keywords} onChange={onKeywordUpdated} />
            </Col>
        </Row>
    );
};

export default HitsFilters;
