import { Select } from "antd";
import { useContext, useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { usePaginatedTable } from "./paginated_table";

export interface Props {
    onChange?: (Keywords: string[]) => void;
    values: string[];
}

export interface keyword {
    value: string;
    label: string;
}

const KeywordSelector = (props: Props) => {
    const {state} = useContext(FirebaseContext);
    const api = state.api;

    const [keywords, setKeywords] = useState<keyword[]|undefined>();

    useEffect(() => {
        onSearch('');
    }, []);

    async function onSearch(value: string) {
        setKeywords(undefined);
        const resp = await api.listKeywords();
        setKeywords(resp.content.map(s => {
            return {value: s.keyword, label: s.keyword}}));
    }

    function onChange(values: string[]) {
        if(props.onChange) props.onChange(values);
    }

    return (
        <Select style={{width: '100%'}} loading={!keywords} placeholder="Select a Keyword"
            showSearch allowClear mode="multiple"
            onChange={onChange}
            value={props.values}
            options={keywords}
        />
    );
};

export default KeywordSelector;
