import { Select } from "antd";
import { useContext, useEffect, useState } from "react";
import FirebaseContext from "../firebase/context";
import { usePaginatedTable } from "./paginated_table";

export interface Props {
    onChange?: (registrars: string[]) => void;
    values: string[];
}

export interface registrar {
    value: string;
    label: string;
}

const RegistrarSelector = (props: Props) => {
    const {state} = useContext(FirebaseContext);
    const api = state.api;

    const [registrars, setRegistrars] = useState<registrar[]|undefined>();

    useEffect(() => {
        onSearch('');
    }, []);

    async function onSearch(value: string) {
        setRegistrars(undefined);
        const resp = await api.listRegistrars(value);
        setRegistrars(resp.content.filter(s => s.length > 0).map(s => {
            return {value: s, label: s}}));
    }

    function onChange(values: string[]) {
        if(props.onChange) props.onChange(values);
    }

    return (
        <Select style={{width: '100%'}} loading={!registrars} placeholder="Select a Registrar"
            onSearch={onSearch} showSearch allowClear mode="multiple"
            onChange={onChange}
            value={props.values}
            options={registrars}
        />
    );
};

export default RegistrarSelector;
