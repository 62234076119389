import axios, { Axios } from "axios";
import { CreateKeywordRequest, DashboardMetrics, Keyword, Page, ScanHit } from "./models";
import * as config from '../config';

export interface ListHitsOpts {
    registrars?: string[];
    domain?: string;
    keywords?: string[];
    inventory?: boolean;
};

export class APIClient {
    private instance: Axios;

    constructor(token: string) {
        this.instance = axios.create({
            baseURL: config.API_BASE_URL,
            headers: {'Authorization': 'Bearer ' + token},
            timeout: 1000,
        });
    }

    public async createKeyword(request: CreateKeywordRequest): Promise<void> {
        await this.instance.post('/v1/keywords', request);
    }

    public async listKeywords(): Promise<Page<Keyword>> {
        const resp = await this.instance.get('/v1/keywords');
        return await resp.data;
    }

    public async getDashboardMetrics(): Promise<DashboardMetrics> {
        return (await this.instance.get('/v1/dashboard')).data;
    }

    public async getHit(hitId: number): Promise<ScanHit> {
        return (await this.instance.get('/v1/hits/' + encodeURIComponent(hitId))).data;
    }

    public async listHits(page: number, size: number, opts: ListHitsOpts): Promise<Page<ScanHit>> {
        return (await this.instance.get('/v1/hits', {
            params: {
                page, size,
                registrars: opts?.registrars?.join('\n'),
                keywords: opts?.keywords?.join('\n'),
                inventory: opts?.inventory ? 'true': null,
                domain: opts?.domain
            }
        })).data;
    }

    public async listRegistrars(filter: string): Promise<Page<string>> {
        return (await this.instance.get('/v1/registrars', {
            params: {
                filter,
            }
        })).data;
    }
}
