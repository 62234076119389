import { Tag } from "antd";
import { ParkingStatusPaid, ParkingStatusRegistrar, ScanHit } from "../api/models";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';


export interface Props {
    scanHit: ScanHit
}

const ScanHitTags = (props: Props) => {
    return (
        <div>
            {props.scanHit.resolvable ? 
                <Tag color="green">DNS <CheckCircleOutlined /></Tag> :
                <Tag color="red">DNS <CloseCircleOutlined /></Tag>}

            {props.scanHit.parkingStatus == ParkingStatusPaid ? <Tag color="geekblue">Parked (Paid)</Tag> : ''}
            {props.scanHit.parkingStatus == ParkingStatusRegistrar ? <Tag color="geekblue">Parked (Registrar)</Tag> : ''}

            {props.scanHit.spamhaus ? <Tag color="orange">Spam</Tag> : ''}
        </div>
    );
};

export default ScanHitTags;
