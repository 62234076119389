import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/error';
import RequiresAuth from './pages/require_auth';
import BaseLayout from './layout/base_layout';
import LoginPage from './pages/login';
import { FirebaseContextProvider } from './firebase/context';
import Portal from './layout/portal';
import DashboardPage from './pages/dashboard';
import KeywordsPage from './pages/keywords';
import AccountSettingsPage from './pages/account_settings';
import ScanHitsPage from './pages/hits';
import SocialHitsPage from './pages/social_hits';
import ViewHitPage from './pages/view_hit';

const router = createBrowserRouter([
  {
    element: <BaseLayout />,
    children: [
      {
        element: <RequiresAuth><Portal/></RequiresAuth>,
        children: [
          {
            path: "/",
            element: <DashboardPage/>,
          },
          {
            path: "/keywords",
            element: <KeywordsPage/>,
          },
          {
            path: "/acquisitions",
            children: [
              {
                path: '',
                element: <ScanHitsPage inventoryOnly={true}/>
              },
            ]
          },
          {
            path: "/hits",
            children: [
              {
                path: '',
                element: <div><ScanHitsPage inventoryOnly={false}/></div> // wrap in div to ensure its reload properly when switching between hits and acquisitions
              },
              {
                path: ":id",
                element: <ViewHitPage/>
              }
            ]
          },
          {
            path: "/social",
            element: <SocialHitsPage/>,
          },
          {
            path: "/settings",
            element: <AccountSettingsPage/>,
          },
        ]
      },
      {
        path: "/login",
        element: <LoginPage/>
      },
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <FirebaseContextProvider>
    <RouterProvider router={router} />
  </FirebaseContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
