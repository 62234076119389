import { Table, TablePaginationConfig, TableProps} from "antd";
import { Page } from "../api/models";
import { AnyObject } from "antd/es/_util/type";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export interface LoadOptions {
    // zero-based page index
    page: number;

    pageSize: number;
};

export type DataSourceLoader<T> = (options: LoadOptions) => Promise<Page<T>>;

export interface Props<T extends AnyObject> extends TableProps<T> {
    dataSourceLoader: DataSourceLoader<T>;
    paginatedTable?: PaginatedTableInstance;
}

export interface PaginatedTableInstance {
    refresh: (resetPagination: boolean) => void;
}

export function usePaginatedTable(): PaginatedTableInstance {
    return {
        refresh: () => {},
    };
};

const PaginatedTable = <T extends AnyObject,>(props: Props<T>) => {
    const [data, setData] = useState<T[]>();
    const [searchParams, setSearchParams] = useSearchParams();

    const pageParam = searchParams.get('page');
    const sizeParam = searchParams.get('size');

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: pageParam ? parseInt(pageParam) : 1, pageSize: sizeParam ? parseInt(sizeParam) : 10, showSizeChanger: true
    });

    useEffect(() => {
        if(pagination && pagination.current && pagination.pageSize) {
            const newParams = new URLSearchParams(searchParams);
            newParams.set('page', pagination.current.toString());
            newParams.set('size', pagination.pageSize.toString());
            setSearchParams(newParams);
        }
    }, [pagination]);

    if(props.paginatedTable) {
        props.paginatedTable.refresh = (resetPagination: boolean) => {
            if(resetPagination) {
                loadData({...pagination, current: 1});
            }
            else {
                loadData(pagination);
            }
        };
    }

    useEffect(() => {
        loadData(pagination);
    }, []);

    async function loadData(pagination: TablePaginationConfig) {
        const currentPage = pagination.current || 1;
        const response = await props.dataSourceLoader({page: currentPage - 1, 
            pageSize: pagination.pageSize || 10});
        setData(response.content);
        setPagination({...pagination, total: response.totalElements});
    }

    function onChange(...args: Parameters<Exclude<TableProps<T>["onChange"], undefined>>) {
        loadData(args[0]);
        if(props.onChange) props.onChange.apply(null, args);
    }


    return (
        <Table {...props} dataSource={data} loading={!data} pagination={pagination} onChange={onChange}/>
    );
};

export default PaginatedTable;
