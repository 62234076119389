import { Card } from "antd";
import { ScanHit } from "../api/models";
import { Link } from "react-router-dom";
import { format_date_short } from "../utils/date_utils";

export interface Props {
    hit: ScanHit;
}

const HitDetailsCard = (props: Props) => {
    return (
        <Card title="Information">
            <table style={{ borderSpacing: '5px' }}>
                <tbody>
                    <tr>
                        <th>TLD: </th>
                        <td>{props.hit.tld}</td>
                    </tr>
                    <tr>
                        <th>Keyword: </th>
                        <td><Link to="/">{props.hit.keyword.keyword}</Link></td>
                    </tr>
                    <tr>
                        <th>Domain First Seen: </th>
                        <td>{format_date_short(props.hit.firstSeenAt)}</td>
                    </tr>
                    <tr>
                        <th>Domain Last Seen: </th>
                        <td>{format_date_short(props.hit.lastSeenAt)}</td>
                    </tr>
                </tbody>
            </table>
        </Card>
    );
};

export default HitDetailsCard;
